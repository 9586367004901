/*
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url("@{public-path}fonts/Lato-Black.ttf") format("opentype");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("@{public-path}fonts/Lato-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("@{public-path}fonts/Lato-Regular.ttf") format("opentype");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: url("@{public-path}fonts/Lato-Light.ttf") format("opentype");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  src: url("@{public-path}fonts/Lato-Thin.ttf") format("opentype");
}
 */
* {
  font-family: "Lato" !important;
  box-sizing: border-box;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  font-size: 90% !important;
}
html,
body,
#root,
.App {
  height: 100%;
}
@media screen and (min-width: 768px) {
  html,
  body,
  #root {
    font-size: 95% !important;
  }
}
@media screen and (min-width: 992px) {
  html,
  body,
  #root {
    font-size: 100% !important;
  }
}
body {
  word-wrap: break-word;
}
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.container {
  max-width: 1460px;
  margin: 0 auto;
  padding: 0 25px;
}
@media screen and (min-width: 767px) {
  .container {
    padding: 0 30px;
  }
}
.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  z-index: 1000;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader-inner {
  width: 80px;
  height: auto;
}
.loader-inner svg {
  max-width: 100%;
}
.portfolio {
  width: 100%;
  height: 100%;
}
.portfolio {
  position: relative;
}
.portfolio-top-nav-btn {
  display: none;
  position: absolute;
  width: 30px;
  height: auto;
  cursor: pointer;
  top: calc(50% - 15px);
  transition: all 0.3s ease-out;
  z-index: 1000;
}
.portfolio-top-nav-btn-left {
  left: 35px;
}
.portfolio-top-nav-btn-left:hover {
  transform: scale(1.15) translateX(-5px);
}
.portfolio-top-nav-btn-right {
  right: 35px;
}
.portfolio-top-nav-btn-right:hover {
  transform: scale(1.15) translateX(5px);
}
.portfolio-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: fixed;
  bottom: 30px;
  z-index: 100;
  width: 100%;
}
.portfolio-item {
  padding: 10px 20px;
  border: 1px solid grey;
  text-transform: uppercase;
  border-radius: 30px;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .portfolio-top-nav-btn {
    display: block;
  }
}
@media screen and (min-width: 1600px) {
  .portfolio-top-nav-btn {
    width: 40px;
    top: calc(50% - 20px);
  }
  .portfolio-top-nav-btn-left {
    left: 40px;
  }
  .portfolio-top-nav-btn-right {
    right: 40px;
  }
}
@media screen and (min-width: 1600px) {
  .portfolio-top-nav-btn-left {
    left: 45px;
  }
  .portfolio-top-nav-btn-right {
    right: 45px;
  }
}
/* GENERAL */
.project-section {
  max-width: 100%;
}
.project-heading {
  font-size: 2.18rem;
  /* 35 */
  color: #303030;
  font-weight: 800;
}
.project-paragraph {
  font-size: 1.25rem;
  /* 20 */
  color: #303030;
  font-weight: 300;
  line-height: 30px;
}
.project-list-ver {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
}
.project-list-ver li {
  width: 100%;
  display: flex;
  align-items: center;
  color: #303030;
  font-weight: 800;
  line-height: 32px;
  font-size: 1.06rem;
  /* 17 */
  gap: 22px;
  position: relative;
  z-index: 2;
}
.project-list-ver li span {
  font-weight: 300;
}
.project-list-ver li small {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #303030;
}
.project-list-ver li div {
  position: absolute;
  width: 100%;
  left: -102%;
  z-index: 1;
  height: 1px;
  background-color: #eee;
}
.project-list-hor {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 15px;
  row-gap: 10px;
  justify-content: center;
}
.project-list-hor li {
  display: flex;
  align-items: center;
  color: #303030;
  font-weight: 800;
  line-height: 32px;
  font-size: 1.06rem;
  /* 17 */
  gap: 15px;
}
.project-list-hor li small {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #303030;
}
.project-list-hor li span {
  font-weight: 300;
}
.project-list-hor .r small {
  display: none;
}
.project-list-hor li:last-child small {
  display: none;
}
@media screen and (min-width: 768px) {
  .project-heading {
    font-size: 2.5rem;
    /* 40 */
  }
}
@media screen and (min-width: 768px) {
  .project-list-ver {
    row-gap: 5px;
  }
  .project-list-ver li {
    font-size: 1.12rem;
    /* 18 */
    gap: 20px;
  }
  .project-list-ver li small {
    width: 9px;
    height: 9px;
  }
  .project-list-hor {
    column-gap: 20px;
  }
  .project-list-hor li {
    font-size: 1.12rem;
    /* 18 */
    gap: 20px;
  }
  .project-list-hor li small {
    width: 9px;
    height: 9px;
  }
}
@media screen and (min-width: 992px) {
  .project-list-ver {
    row-gap: 10px;
  }
  .project-list-ver li {
    font-size: 1.25rem;
    gap: 25px;
  }
  .project-list-ver li small {
    width: 10px;
    height: 10px;
  }
  .project-list-hor {
    column-gap: 30px;
  }
  .project-list-hor li {
    font-size: 1.25rem;
    gap: 30px;
  }
  .project-list-hor li small {
    width: 10px;
    height: 10px;
  }
}
/* HEADER */
.project-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.project-header .logo {
  width: 100px;
  height: auto;
}
@media screen and (min-width: 767px) {
  .project-header .logo {
    width: 200px;
  }
}
@media screen and (min-width: 1200px) {
  .project-header .logo {
    width: 230px;
  }
}
@media screen and (min-width: 1600px) {
  .project-header .logo {
    width: 250px;
  }
}
@media screen and (min-width: 1992px) {
  .project-header .logo {
    width: 280px;
  }
}
/* BODY */
/* body intro*/
.project-body .intro {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.project-body .intro-devices {
  max-width: 100%;
  margin: 0 auto;
  margin-top: 50px;
}
.project-body .intro-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.project-body .intro-heading h3 {
  font-size: 3rem;
  font-weight: 800;
  color: #303030;
}
.project-body .intro-heading p {
  font-size: 1.2rem;
  font-weight: 300;
  color: #303030;
  text-align: center;
  margin-top: 3px;
}
.project-body .intro-icon {
  margin-top: 50px;
  width: 17px;
  overflow: visible;
  height: auto;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.project-body .intro-icon path {
  transition: all 0.3s ease-out;
}
.project-body .intro-icon:hover,
.project-body .intro-icon:focus,
.project-body .intro-icon:active {
  transform: scale(1.15);
}
.project-body .intro-icon:hover path:nth-child(2),
.project-body .intro-icon:focus path:nth-child(2),
.project-body .intro-icon:active path:nth-child(2) {
  transform: translateY(42%);
}
.project-body .intro-icon:hover path:last-child,
.project-body .intro-icon:focus path:last-child,
.project-body .intro-icon:active path:last-child {
  transform: translateY(5px);
}
.project-body .intro-nav-btn {
  display: block;
  width: 20px;
  top: calc(50% - 10px);
}
.project-body .intro-nav-btn-left {
  left: 20px;
}
.project-body .intro-nav-btn-right {
  right: 20px;
}
@media screen and (min-width: 767px) {
  .project-body .intro {
    padding-bottom: 200px;
  }
  .project-body .intro-heading h3 {
    font-size: 5rem;
  }
  .project-body .intro-heading p {
    font-size: 1.87rem;
  }
  .project-body .intro-icon {
    width: 20px;
  }
  .project-body .intro-nav-btn {
    width: 30px;
    top: calc(50% - 15px);
  }
  .project-body .intro-nav-btn-left {
    left: 25px;
  }
  .project-body .intro-nav-btn-right {
    right: 25px;
  }
}
@media screen and (min-width: 992px) {
  .project-body .intro {
    padding-bottom: 250px;
  }
  .project-body .intro-devices {
    max-width: 75%;
    margin-top: 70px;
    display: flex;
    justify-content: center;
  }
  .project-body .intro-heading {
    margin-top: -20px;
  }
  .project-body .intro-nav-btn {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .project-body .intro-heading {
    margin-top: -60px;
  }
  .project-body .intro-devices img {
    max-width: 95%;
  }
}
@media screen and (min-width: 1600px) {
  .project-body .intro-heading {
    margin-top: -80px;
  }
}
@media screen and (min-width: 1992px) {
  .project-body .intro-devices {
    margin-top: 100px;
  }
  .project-body .intro-icon {
    margin-top: 80px;
  }
}
/* project desc */
.project-body .description {
  position: relative;
  width: 100%;
  height: fit-content;
  border-top: 1px solid #dcdddd;
  border-bottom: 1px solid #dcdddd;
  padding-top: 70px;
  padding-bottom: 70px;
}
.project-body .description-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.project-body .description-sub-heading {
  margin-top: 15px;
  margin-bottom: 50px;
}
@media screen and (min-width: 768px) {
  .project-body .description-content h4 {
    font-size: 2.5rem;
    /* 40 */
  }
}
@media screen and (min-width: 992px) {
  .project-body .description {
    padding-top: 120px;
    padding-bottom: 100px;
  }
  .project-body .description-sub-heading {
    margin-top: 22px;
    margin-bottom: 80px;
  }
}
/* visuals */
.project-body .visuals {
  width: 100%;
  height: fit-content;
  padding: 70px 0 80px 0;
}
.project-body .visuals-gallery {
  position: relative;
}
.project-body .visuals-gallery-overlay {
  display: none !important;
  position: fixed;
  inset: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-out;
}
.project-body .visuals-gallery-overlay-inner {
  position: relative;
  width: 75%;
  max-width: 1000px;
  text-align: center;
}
.project-body .visuals-gallery-overlay-inner > img {
  border-radius: 8px;
}
.project-body .visuals-gallery-overlay-inner > span {
  color: #fff;
  font-size: 1.37rem;
  /* 22 */
  font-weight: 600;
  margin-top: 20px;
  display: inline-block;
}
.project-body .visuals-gallery-overlay-close {
  position: absolute;
  top: -25px;
  right: -25px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-size: 1.37rem;
  /* 22 */
  color: #fff;
}
.project-body .visuals-gallery-overlay-close:hover {
  transform: scale(1.1);
}
.project-body .visuals-gallery-overlay-prev {
  position: absolute;
  top: 45%;
  left: -45px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #fff;
}
.project-body .visuals-gallery-overlay-prev:hover {
  transform: translateX(-7px);
}
.project-body .visuals-gallery-overlay-next {
  position: absolute;
  top: 45%;
  right: -45px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  color: #fff;
}
.project-body .visuals-gallery-overlay-next:hover {
  transform: translateX(7px);
}
.project-body .visuals-gallery-overlay-next svg {
  font-size: 2.81rem;
  /* 45 */
}
.project-body .visuals-gallery-overlay-prev svg {
  font-size: 2.81rem;
  /* 45 */
}
.project-body .visuals-gallery-nav {
  position: absolute;
  bottom: -40px;
  left: 50%;
  display: flex;
  gap: 5px;
}
.project-body .visuals-gallery-nav small {
  width: 7px;
  height: 7px;
  background: #eee;
  border-radius: 50%;
  display: inline-block;
  transition: all 0.2s ease-out;
  cursor: pointer;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
}
.project-body .visuals-gallery-nav small:hover {
  transform: scale(1.2);
}
.project-body .visuals-gallery-nav .active {
  background: #303030;
}
.project-body .visuals-gallery-inner {
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  /*overflow-y: auto; */
}
.project-body .visuals-gallery-inner .swiper-scrollbar {
  display: none !important;
}
.project-body .visuals-gallery-inner .swiper-pagination-bullet {
  background-color: #000;
}
@media screen and (min-width: 767px) {
  .project-body .visuals-gallery-inner .swiper-pagination {
    bottom: 0px;
  }
}
.project-body .visuals-gallery-inner::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.project-body .visuals-gallery-item {
  box-shadow: 4px 12px 20px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  transition: all 0.2s ease-out;
  overflow: hidden;
  border-radius: 3px;
  position: relative;
}
.project-body .visuals-gallery-item .overlay {
  opacity: 0;
  transition: all 0.3s ease-out;
  background: #000;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 15px;
}
.project-body .visuals-gallery-item .overlay span {
  font-size: 1.25rem;
  /* 20 */
  font-weight: 600;
  color: #fff;
}
.project-body .visuals-gallery-item:hover .overlay {
  opacity: 0.75;
}
.project-body .visuals-gallery-item-anim-enter-active,
.project-body .visuals-gallery-item-anim-exit-active {
  transition: all 0.7s;
}
.project-body .visuals-gallery-item-anim-enter {
  opacity: 0;
  transform: scale(0.75);
}
.project-body .visuals-gallery-item-anim-enter-active {
  opacity: 1;
  transform: translateX(0);
}
.project-body .visuals-gallery-item-anim-exit {
  opacity: 1;
}
.project-body .visuals-gallery-item-anim-exit-active {
  opacity: 0;
  transform: scale(0.75);
}
@media screen and (min-width: 600px) {
  /*.project-body .visuals-gallery-item {
    flex: 0 0 calc(50% - 30px);
  } */
}
@media screen and (min-width: 767px) {
  /*.project-body .visuals-gallery .visuals-gallery-item:last-child {
    margin-left: 20px;
    margin-right: auto;
  } */
  /*
  .project-body .visuals-gallery-item {
    flex: 0 0 calc(33% - 30px);
  } */
  .project-body .visuals-gallery-overlay {
    display: flex !important;
  }
  .project-body .visuals-gallery-overlay-prev {
    left: -55px;
  }
  .project-body .visuals-gallery-overlay-next {
    right: -55px;
  }
}
@media screen and (min-width: 992px) {
  .project-body .visuals {
    margin-top: 50px;
    padding-bottom: 100px;
  }
  .project-body .visuals-gallery-inner {
    padding: 30px 0;
  }
  /*
  .project-body .visuals-gallery .visuals-gallery-item:last-child {
    padding-left: 0;
    margin-right: 0;
  } */
  /*
  .project-body .visuals-gallery-item {
    flex: 0 0 calc(25% - 30px);
  }
 */
  .project-body .visuals-gallery-overlay-inner {
    width: 70%;
  }
  .project-body .visuals-gallery-overlay-prev {
    left: -75px;
  }
  .project-body .visuals-gallery-overlay-next {
    right: -75px;
  }
}
/* coding */
.project-body .coding {
  padding-top: 70px;
  padding-bottom: 80px;
  border-top: 1px solid #dcdddd;
}
.project-body .coding-list {
  padding-top: 80px;
}
.project-body .coding-bullet {
  height: 10px;
  width: 10px;
  background-color: black;
  border-radius: 50%;
}
@media screen and (min-width: 992px) {
  .project-body .coding {
    padding-top: 120px;
    padding-bottom: 150px;
  }
  .project-body .coding-list {
    padding-top: 110px;
  }
}
/* blockchain */
.project-body .blockchain {
  padding: 70px 0 70px 0;
  border-top: 1px solid #dcdddd;
}
.project-body .blockchain-heading-txt {
  margin: 50px 0 40px 0;
}
.project-body .blockchain-sub-heading {
  font-size: 1.25rem;
  /* 20 */
  font-weight: 600;
  color: #303030;
}
.project-body .blockchain-tech-list .project-list-hor {
  padding: 60px 0 60px 0;
}
.project-body .blockchain-contract-func-list {
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  /*column-gap: 50px; */
  row-gap: 25px;
  justify-content: flex-start;
}
.project-body .blockchain-contract-func-list-i {
  flex: 0 0 100%;
}
.project-body .blockchain-contract-func-list-i h6 {
  font-size: 1.06rem;
  font-weight: 600;
  color: #303030;
  margin-bottom: 15px;
}
.project-body .blockchain-contract-func-list-i p {
  font-size: 0.93rem;
  /* 15 */
  font-weight: 300;
  color: #303030;
  line-height: 22px;
}
@media screen and (min-width: 600px) {
  .project-body .blockchain-contract-func-list {
    margin-left: -25px;
    margin-right: -25px;
  }
  .project-body .blockchain-contract-func-list-i {
    flex: 0 0 50%;
    padding: 0 25px 0 25px;
  }
}
@media screen and (min-width: 767px) {
  .project-body .blockchain-contract-func-list-i {
    flex: 0 0 33.33%;
  }
}
@media screen and (min-width: 992px) {
  .project-body .blockchain {
    padding: 120px 0 120px 0;
  }
  .project-body .blockchain-heading-txt {
    margin: 70px 0 50px 0;
  }
  .project-body .blockchain-contract-func-list-i {
    flex: 0 0 calc(25% - 50px);
  }
}
@media screen and (min-width: 1200px) {
  .project-body .blockchain-contract-func-list-i {
    flex: 0 0 20%;
  }
}
/* corporate identity */
.project-body .corporate {
  padding: 70px 0 80px 0;
  border-top: 1px solid #dcdddd;
}
.project-body .corporate-list {
  padding-top: 90px;
}
@media screen and (min-width: 992px) {
  .project-body .corporate {
    padding: 120px 0 140px 0;
  }
  .project-body .corporate-list {
    padding-top: 140px;
  }
}
/* media */
.project-body .media {
  padding: 70px 0 90px 0;
  border-top: 1px solid #dcdddd;
}
.project-body .media-list {
  margin-top: 70px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}
.project-body .media-list-item {
  flex: 0 0 calc(100%);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 25px;
  padding: 40px 40px 44px 40px;
  box-shadow: -1px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  transition: all 0.3s ease-out;
  background: #fff;
}
.project-body .media-list-item-header {
  transition: all 0.3s ease-out;
}
.project-body .media-list-item-header:hover {
  transform: scale(1.05);
}
.project-body .media-list-item-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.project-body .media-list-item-content p {
  font-size: 1.06;
  /* 17 */
  font-weight: 300;
  color: #303030;
}
.project-body .media-list-item-content small {
  display: inline-block;
  font-size: 1.06rem;
  /* 17 */
  margin-top: 25px;
  font-weight: 600;
}
@media screen and (min-width: 600px) {
  .project-body .media-list {
    margin-left: -15px;
    margin-right: -15px;
  }
  .project-body .media-list-item {
    flex: 0 0 calc(50% - 30px);
  }
  /*
  .project-body .media-list .media-list-item:last-child {
    margin-right: auto;
    margin-left: 15px;
  } */
}
@media screen and (min-width: 992px) {
  .project-body .media {
    padding: 120px 0 180px 0;
  }
  .project-body .media-list {
    margin-top: 90px;
  }
  /*
  .project-body .media-list .media-list-item:last-child {
    margin-right: 0;
    margin-left: 0;
  } */
  .project-body .media-list-item {
    flex: 0 0 calc(33.33% - 30px);
  }
}
/* pre footer */
.project-body .pre-footer {
  padding: 110px 0 150px 0;
  border-top: 1px solid #dcdddd;
}
.project-body .pre-footer-content {
  text-align: center;
}
.project-body .pre-footer-content h4 {
  font-size: 1.5rem;
  font-weight: 300;
  color: #303030;
  margin-bottom: 20px;
}
.project-body .pre-footer-content > a {
  text-decoration: none;
}
.project-body .pre-footer-content h3 {
  font-size: 2rem;
  font-weight: 600;
  color: #303030;
}
@media screen and (min-width: 767px) {
  .project-body .pre-footer-content h4 {
    font-size: 1.7rem;
  }
  .project-body .pre-footer-content h3 {
    font-size: 2.2rem;
  }
}
@media screen and (min-width: 992px) {
  .project-body .pre-footer {
    padding: 170px 0 210px 0;
  }
  .project-body .pre-footer-content h4 {
    font-size: 1.87rem;
  }
  .project-body .pre-footer-content h3 {
    font-size: 2.5rem;
  }
}
/* footer */
.project-body .footer {
  padding: 120px 0 40px 0;
  border-top: 1px solid #dcdddd;
  background: #f4f5f5;
}
.project-body .footer-content {
  display: flex;
  flex-direction: column;
  row-gap: 100px;
  /* 150 */
  align-items: center;
}
.project-body .footer-actions {
  display: flex;
  justify-content: center;
  gap: 30px;
}
.project-body .footer-actions a {
  display: inline-block;
  width: 150px;
  position: relative;
}
.project-body .footer-actions .action {
  position: absolute;
  inset: 0;
}
.project-body .footer-actions .action-h {
  position: absolute;
  inset: 0;
  opacity: 0;
}
.project-body .footer-actions a:hover .action-h {
  opacity: 1;
}
.project-body .footer-actions a:hover .action {
  opacity: 0;
}
.project-body .footer-content > img {
  max-width: 30%;
  margin-top: 43px;
}
.project-body .footer-content > small {
  font-size: 0.93rem;
  /* 15 */
  font-weight: 300;
  color: #303030;
}
@media screen and (min-width: 767px) {
  .project-body .footer-content > img {
    max-width: 20%;
  }
}
@media screen and (min-width: 992px) {
  .project-body .footer {
    padding: 160px 0 60px 0;
  }
  .project-body .footer-content {
    row-gap: 150px;
  }
  .project-body .footer-content > img {
    max-width: 15%;
  }
}
@media screen and (min-width: 1200px) {
  .project-body .footer-content > img {
    max-width: 10%;
  }
}
/* SMART TRUST */
@media screen and (min-width: 768px) {
  .smarttrust-description {
    background-image: url("../../../public/assets/images/smarttrust/bgr_description.jpg");
    background-repeat: no-repeat;
    background-size: 1500px auto;
    background-position: bottom right -80px;
  }
}
@media screen and (min-width: 992px) {
  .smarttrust-description {
    background-size: contain;
    background-position: bottom right;
    padding-top: 120px;
    padding-bottom: 100px;
  }
}
.smarttrust-visuals {
  background-image: url("../../../public/assets/images/triangle_001.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
}
.smarttrust-blockchain {
  background-image: url("../../../public/assets/images/triangle_blockchain.jpeg");
  background-repeat: no-repeat;
  background-size: 50% auto;
  background-position: top right;
}
.smarttrust-media {
  background-image: url("../../../public/assets/images/triangle_003.png");
  background-repeat: no-repeat;
  background-size: 30% auto;
  background-position: bottom left;
}
@media screen and (min-width: 992px) {
  .smarttrust-media {
    background-size: 10% auto;
  }
}
/* KIM */
@media screen and (min-width: 1200px) {
  .kim-description .description-content {
    background-image: url("../../../public/assets/images/kim/desc_img.png");
    background-repeat: no-repeat;
    background-size: 60% auto;
    background-position: bottom -20px right;
  }
}
.kim-description .description-image {
  display: block;
  margin-top: 20px;
}
@media screen and (min-width: 1200px) {
  .kim-description .description-image {
    display: none;
  }
}
/* CRYPTOSWAP */
.cryptoswap-description {
  background-image: url("../../../public/assets/images/cryptoswap/bgr_description.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  /* 100 100 */
  background-position: top right;
}
.cryptoswap-media {
  background-image: url("../../../public/assets/images/cryptoswap/bgr_media.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  /* 100 auto*/
  background-position: bottom left;
}
.smarttrust-loader circle {
  stroke: #11bdaf;
}
.kim-loader circle {
  stroke: #c74837;
}
.cryptoswap-loader circle {
  stroke: #31353f;
}
.ca {
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
}
.ca ::selection {
  background: rgba(103, 107, 218, 0.99) !important;
}
.ca ::-moz-selection {
  background: #595ed7 !important;
}
.ca h1 {
  font-size: 2.8125rem;
  letter-spacing: 0.0625rem;
  font-weight: 800;
}
.ca-list {
  line-height: 22px;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 20px;
}
.ca-list li {
  font-size: 1.0625rem;
  letter-spacing: 0.04375rem;
  font-weight: 300;
}
.ca-list li::marker {
  font-size: 22px;
  position: relative;
  top: 1px;
}
.ca-divider-heading {
  text-align: center;
  display: flex;
  /*
    & small {
      display: inline-block;
      height: 1px;
      background: #fff;
      width: 100%;
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      z-index: 1;
    } */
}
.ca-divider-heading::before {
  color: #47485d;
  position: relative;
  top: 50%;
  width: 50%;
  border-block-start: 1px solid transparent;
  border-block-start-color: inherit;
  border-block-end: 0;
  transform: translateY(50%);
  content: "";
}
.ca-divider-heading::after {
  color: #47485d;
  position: relative;
  top: 50%;
  width: 50%;
  border-block-start: 1px solid transparent;
  border-block-start-color: inherit;
  border-block-end: 0;
  transform: translateY(50%);
  content: "";
}
.ca-divider-heading span {
  display: inline-block;
  color: #fff;
  padding: 0 30px;
  font-size: 1.0625rem;
  font-weight: 800;
  white-space: nowrap;
}
.ca-divider-heading .logo {
  max-width: 130px;
  position: relative;
  top: 13px;
  margin: 0 30px;
}
.ca-intro {
  position: relative;
}
.ca-intro-badge {
  position: absolute;
  top: 0;
  left: 0;
}
.ca-intro-badge-inner {
  position: relative;
}
.ca-intro-badge-inner img {
  position: absolute;
  top: 0;
  left: 0;
  width: 30%;
}
.ca-intro-badge-inner div {
  padding: 10px 20px;
  background: #2c2e60;
  line-height: 100%;
}
.ca-intro-badge-inner div small {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 0.625rem;
  letter-spacing: 0.03125rem;
  margin-left: 30px;
}
.ca-intro-top {
  display: flex;
  padding-top: 120px;
  flex-direction: column;
  overflow: hidden;
}
.ca-intro-l {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  color: #fff;
  margin-bottom: 80px;
}
.ca-intro-l .subheading {
  margin: 8px 0 17px 0;
  line-height: 18px;
}
.ca-intro-l p {
  font-size: 0.9375rem;
}
.ca-intro-l p small {
  font-weight: 800;
}
.ca-intro-r {
  position: relative;
  right: -25px;
}
.ca-intro-nav {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin: 70px 0 70px 0;
}
.ca-intro-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 5px 20px 15px 20px;
  color: #fff;
  text-align: left;
}
.ca-intro-nav-item:first-child {
  border-left: none;
}
.ca-intro-nav-item-cnt {
  width: 100%;
  height: 100%;
}
.ca-intro-nav-item-cnt a {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
  justify-content: flex-start;
}
.ca-intro-nav-item .icon {
  width: 60px;
  min-width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-out;
  cursor: pointer;
}
.ca-intro-nav-item .icon:hover,
.ca-intro-nav-item .icon:focus {
  background: #ffffff;
  transform: scale(0.8);
}
.ca-intro-nav-item .icon:hover .st0,
.ca-intro-nav-item .icon:focus .st0 {
  stroke: #000;
}
.ca-intro-nav-item .icon svg {
  width: 55%;
}
.ca-intro-nav-item .icon .st0 {
  fill: none;
  stroke: #fff;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.ca-intro-nav-item p {
  font-size: 1.0625rem;
  font-weight: 800;
  letter-spacing: 0.3px;
}
.ca-design {
  width: 100%;
  position: relative;
  margin: 0 0;
  padding-left: 0;
  padding-right: 0;
  margin-top: 120px;
}
.ca-design-bgr .default {
  display: none;
}
.ca-design-bgr .mobile {
  display: block;
}
.ca-design-content {
  color: #fff;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 25px;
  margin-bottom: 50px;
}
.ca-design-content h1 {
  margin-right: auto;
}
.ca-design-content ul {
  margin-top: 20px;
}
.ca-planets {
  width: 100%;
  margin-top: 120px;
}
.ca-planets-list {
  margin-top: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 50px;
  gap: 50px;
}
.ca-planets-list-item {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 0 0 calc(50% - 25px);
}
.ca-planets-list-item p {
  margin: 13px 0 0;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8125rem;
  font-weight: 800;
}
.ca-summary {
  margin-top: 120px;
}
.ca-summary-content {
  display: none;
  flex-direction: column;
  gap: 25px;
}
.ca-summary-content-m {
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 70px;
}
.ca-summary-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 180px;
}
.ca-summary-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
}
.ca-summary-list-item-img {
  display: block;
  height: 100vh;
  max-height: 300px;
  object-fit: contain;
}
.ca-summary-list-item-img.o1 {
  margin-left: -7%;
}
.ca-summary-list-item-img-m {
  display: block;
  max-width: 230px;
  height: auto;
  object-fit: contain;
}
.ca-summary-list-item-img-m.o1 {
  margin-left: -20px;
}
.ca-summary-list-item .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: 70%;
}
.ca-summary-list-item h3 {
  font-size: 2.1875rem;
  font-weight: 800;
}
.ca-footer {
  position: relative;
  text-align: center;
  margin-top: 230px;
  overflow: hidden;
  flex-direction: column;
}
.ca-footer .content {
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -150px;
  margin-top: 50px;
}
.ca-footer .content > a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 4px;
  font-weight: 300;
  opacity: 1;
}
.ca-footer > img {
  position: relative;
  bottom: -2px;
}
.ca-footer .d {
  display: none;
  margin: 0 auto;
  margin-top: 150px;
}
.ca-footer .cpr {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  display: none;
}
.ca-footer .cpr p {
  font-size: 11px;
  opacity: 0.4;
  letter-spacing: 1px;
}
@media screen and (max-width: 576px) {
  .ca-planets-list {
    row-gap: 40px;
  }
  .ca-planets-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
@media screen and (min-width: 576px) {
  .ca-planets-list-item {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 0 0 calc(50% - 50px);
  }
  .ca-footer-cpr {
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .ca h1 {
    font-size: 3.125rem;
    letter-spacing: 0.1875rem;
    font-weight: 800;
  }
  .ca-intro-badge-inner {
    position: relative;
  }
  .ca-intro-badge-inner img {
    position: absolute;
    top: 0;
    left: 0;
    width: 33%;
  }
  .ca-intro-badge-inner div {
    padding: 12px 40px;
    background: #2c2e60;
  }
  .ca-intro-badge-inner div small {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.6875rem;
    letter-spacing: 0.03125rem;
    margin-left: 25px;
  }
  .ca-intro-top {
    padding-top: 0px;
    flex-direction: row;
    overflow: hidden;
  }
  .ca-intro-l {
    flex: 0 0 50%;
    padding-top: 125px;
    margin-bottom: 0;
  }
  .ca-intro-l h1 {
    font-size: 2.8125rem;
  }
  .ca-intro-l .subheading {
    margin: 8px 0 17px 0;
    line-height: 20px;
  }
  .ca-intro-l p {
    font-size: 1rem;
  }
  .ca-intro-l p small {
    font-weight: 800;
  }
  .ca-intro-r {
    flex: 0 0 50%;
    padding-top: 70px;
  }
  .ca-intro-nav {
    flex-direction: row;
    margin: 110px 0 0 0;
    gap: 0;
  }
  .ca-intro-nav-item {
    text-align: center;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
  .ca-intro-nav-item-cnt a {
    flex-direction: column;
    padding: 5px 20px;
  }
  .ca-design {
    margin-top: 150px;
  }
  .ca-design-bgr img {
    max-width: 90%;
  }
  .ca-design-bgr .default {
    display: block;
  }
  .ca-design-bgr .mobile {
    display: none;
  }
  .ca-design-content {
    position: absolute;
    right: 0;
    top: 40%;
    align-items: flex-end;
  }
  .ca-summary {
    margin-top: 150px;
  }
  .ca-summary-content {
    display: flex;
    margin-top: 100px;
    gap: 50px;
  }
  .ca-summary-content-m {
    margin-top: 100px;
    display: none;
  }
  .ca-summary-list {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 50px;
  }
  .ca-summary-list-item {
    flex: 0 0 calc(33.33% - 50px);
    justify-content: space-around;
    gap: 80px;
  }
  .ca-summary-list-item-img-m {
    display: none;
  }
  .ca-summary-list-item .content {
    gap: 50px;
    max-width: 100%;
  }
  .ca-planets {
    margin-top: 150px;
    gap: 80px;
  }
  .ca-planets-list {
    margin-top: 100px;
  }
  .ca-planets-list-item {
    flex: 0 0 calc(33.33% - 80px);
  }
  .ca-planets-list-item img {
    max-width: 100%;
  }
  .ca-planets-list-item p {
    font-size: 0.6875rem;
  }
  .ca-footer .d {
    display: block;
  }
  .ca-footer .m {
    display: none;
  }
  .ca-footer .cpr {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 15px 0;
  }
}
@media screen and (min-width: 992px) {
  .ca h1 {
    font-size: 3.4375rem;
    letter-spacing: 0.1875rem;
    font-weight: 800;
  }
  .ca-divider-heading .logo {
    max-width: 120px;
    top: 12px;
  }
  .ca-intro-l {
    padding-top: 170px;
  }
  .ca-intro-l .subheading {
    margin: 10px 0 20px 0;
    line-height: 25px;
  }
  .ca-intro-l p {
    font-size: 1.125rem;
  }
  .ca-intro-l p small {
    font-weight: 800;
  }
  .ca-intro-r {
    padding-top: 90px;
  }
  .ca-design {
    margin-top: 200px;
    max-width: 90%;
  }
  .ca-planets {
    margin-top: 180px;
  }
  .ca-planets-list {
    margin-top: 140px;
    row-gap: 80px;
  }
  .ca-planets-list-item {
    flex: 0 0 calc(25% - 80px);
  }
  .ca-summary {
    margin-top: 180px;
  }
  .ca-summary-content {
    margin-top: 160px;
    gap: 70px;
  }
  .ca-summary-list {
    gap: 40px;
  }
  .ca-summary-list-item {
    flex: 0 0 calc(33.33% - 40px);
  }
  .ca-footer {
    position: relative;
    margin-top: 250px;
  }
  .ca-footer img {
    max-width: 90%;
  }
}
@media screen and (min-width: 1200px) {
  .ca h1 {
    font-size: 4.375rem;
    letter-spacing: 0.1875rem;
    font-weight: 800;
  }
  .ca-intro-nav {
    max-width: 70%;
  }
  .ca-design {
    margin-top: 250px;
  }
  .ca-design-bgr img {
    max-width: 100%;
  }
  .ca-planets {
    margin-top: 200px;
  }
  .ca-summary {
    margin-top: 280px;
  }
  .ca-summary-list {
    gap: 50px;
  }
  .ca-summary-list-item {
    flex: 0 0 calc(33.33% - 50px);
  }
  .ca-footer {
    margin-top: 280px;
  }
  .ca-footer img {
    max-width: 75%;
  }
}
@media screen and (min-width: 1300px) {
  .ca-summary-list-item {
    flex: 0 0 20%;
  }
}
@media screen and (min-width: 1600px) {
  .ca-intro-nav {
    max-width: 60%;
  }
  .ca-design {
    max-width: 95%;
  }
}
@media screen and (min-width: 1900px) {
  .ca-divider-heading .logo {
    max-width: 150px;
    top: 15px;
  }
  .ca-design {
    max-width: 80%;
  }
}
@media screen and (min-width: 2200px) {
  .ca-design {
    max-width: 75%;
  }
}
@font-face {
  font-family: 'Abhaya Libre';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/abhayalibre/v14/e3tmeuGtX-Co5MNzeAOqinEgew.ttf) format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZg.ttf) format('truetype');
}
.erbia-landscape .erbia-heading span:before,
.erbia-landscape .erbia-heading span:after {
  width: 150px;
}
.erbia-landscape .erbia-main {
  padding-bottom: 130px;
}
.erbia-landscape .erbia-intro {
  height: 100%;
  background-size: contain;
}
.erbia-landscape .erbia-intro-content {
  padding: 50px 0;
}
.erbia-landscape .erbia-intro-content .logo {
  width: 15%;
  margin-bottom: 40px;
}
.erbia-landscape .erbia-intro-content h1 {
  font-size: 2.1875rem;
  line-height: 2.8125rem;
}
.erbia-landscape .erbia-resources {
  margin-top: 50px;
}
.erbia-landscape .erbia-resources-items {
  margin-top: 50px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 50px;
  padding: 0 50px;
}
.erbia-landscape .erbia-resources-items .item {
  flex: 0 0 calc(33.33% - 50px);
}
.erbia-landscape .erbia-resources-items .item p {
  font-size: 1.375rem;
  line-height: 1.625rem;
}
.erbia-landscape .erbia-custom {
  margin-top: 50px;
}
.erbia-landscape .erbia-custom-items {
  margin-top: 50px;
  flex-direction: row;
  gap: 20px;
}
.erbia-landscape .erbia-custom-items .item {
  flex: 0 0 calc(20% - 20px);
}
.erbia-landscape .erbia-mode .login-form {
  flex-direction: row;
  justify-content: center;
  gap: 25px;
}
.erbia-landscape .erbia-mode .login-form img {
  max-width: 45%;
}
.erbia-landscape .erbia-colors-items {
  gap: 20px;
}
.erbia-landscape .erbia-colors-items .item {
  flex: 0 0 calc(25% - 20px);
}
.erbia {
  width: 100%;
  background: #000;
  color: #fff;
  position: relative;
}
.erbia *::selection {
  background: #c19a5b !important;
}
.erbia-section {
  overflow: hidden;
}
.erbia-section-content {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 25px 0 25px;
}
.erbia-line {
  max-height: 100%;
  position: absolute;
  bottom: 75px;
  width: fit-content;
  display: none;
}
.erbia-line-left {
  left: 15px;
}
.erbia-line-right {
  right: 15px;
}
.erbia-heading {
  text-align: center;
  margin: 0 auto;
}
.erbia-heading span {
  position: relative;
  display: inline-block;
  font-size: 3.4375rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.8);
  letter-spacing: 3px;
  /*
      &::selection {
        background: #c19a5b !important;
      } */
}
.erbia-heading > span:before,
.erbia-heading > span:after {
  content: "";
  position: absolute;
  top: 50%;
  border-bottom: 1px solid #35353b;
  width: 0px;
}
.erbia-heading > span:before {
  right: 100%;
  margin-right: 20px;
}
.erbia-heading > span:after {
  left: 100%;
  margin-left: 20px;
}
.erbia-main {
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  background-image: url("../../../public/assets/images/erbia/artboard-bck.jpg");
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.erbia-intro {
  width: 100%;
  height: 100vh;
  background-image: url("../../../public/assets/images/erbia/intro-bck.jpg");
  background-size: 210% auto;
  background-position: top center;
  background-repeat: no-repeat;
}
.erbia-intro .erbia-section-content {
  height: 100%;
}
.erbia-intro-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: clamp(80px, 10vh, 130px);
  flex-direction: column;
  align-items: center;
}
.erbia-intro-content .logo {
  width: 30%;
  margin-bottom: 50px;
}
.erbia-intro-content h1 {
  font-family: "Inter" !important;
  font-weight: 400;
  font-size: 3.125rem;
  line-height: 3.75rem;
  letter-spacing: 0.625rem;
  word-spacing: 9999px;
  text-transform: uppercase;
  text-align: center;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-color: initial;
  background-image: linear-gradient(180deg, #e3a455 7.29%, #f6dba6 38.02%, #ffebc3 58.33%, #ba7f3b 100%);
  background-size: 100%;
  margin-bottom: 30px;
  user-select: none;
}
.erbia-intro-content svg {
  transition: all 0.3s ease-out;
  cursor: pointer;
  width: 60px;
}
.erbia-intro-content svg:hover,
.erbia-intro-content svg:focus,
.erbia-intro-content svg:active {
  transform: scale(1.15) translateY(15px);
}
.erbia-resources {
  margin-top: 80px;
  position: relative;
  z-index: 2;
}
.erbia-resources-h {
  margin: 0 auto;
}
.erbia-resources-h > span:before,
.erbia-resources-h > span:after {
  margin: 0 80px;
}
.erbia-resources-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
}
.erbia-resources-items .item {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 60px 0;
}
.erbia-resources-items .item-icon {
  width: 100%;
  padding-top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0b0c16;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s ease-out;
  border: 2px solid transparent;
}
.erbia-resources-items .item-icon:hover,
.erbia-resources-items .item-icon:focus,
.erbia-resources-items .item-icon:active {
  border-color: #b58952;
}
.erbia-resources-items .item-icon:hover svg,
.erbia-resources-items .item-icon:focus svg,
.erbia-resources-items .item-icon:active svg {
  transform: scale(1.15);
}
.erbia-resources-items .item-icon-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
}
.erbia-resources-items .item-icon svg {
  max-width: 55%;
  height: auto;
  transition: all 0.3s ease-out;
}
.erbia-resources-items .item p {
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 300;
  letter-spacing: 0.5px;
  margin-top: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  white-space: nowrap;
}
.erbia-custom {
  margin-top: 100px;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: visible;
}
.erbia-custom-bck {
  position: absolute;
  max-width: 70%;
  height: auto;
  top: -100%;
  opacity: 0.9;
  display: none;
}
.erbia-custom-items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 80px;
}
.erbia-custom-items .item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 calc(50% - 30px);
}
.erbia-custom-items .item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 100%;
  background: #0b0c16;
  border-radius: 50%;
  transition: all 0.3s ease-out;
  position: relative;
  border: 2px solid transparent;
}
.erbia-custom-items .item-icon:hover,
.erbia-custom-items .item-icon:focus,
.erbia-custom-items .item-icon:active {
  border-color: #b58952;
}
.erbia-custom-items .item-icon:hover svg,
.erbia-custom-items .item-icon:focus svg,
.erbia-custom-items .item-icon:active svg {
  transform: scale(1.15);
}
.erbia-custom-items .item-icon-inner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
}
.erbia-custom-items .item-icon-inner svg {
  max-width: 40%;
  transition: all 0.3s ease-out;
}
.erbia-mode {
  margin-top: 100px;
}
.erbia-mode .erbia-section-content {
  max-width: 1240px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.erbia-mode-img {
  max-width: 95%;
  margin-top: 15px;
}
.erbia-mode-mobile {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.erbia-mode-mobile .login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.erbia-mode-desktop {
  display: none;
  margin-top: 20px;
}
.erbia-mode-desktop .group {
  display: flex;
  gap: 10px;
  padding: 0 30px;
}
.erbia-mode-desktop .group-item img {
  position: relative;
  z-index: 10;
}
.erbia-mode-desktop .group-top .group-item:first-child {
  flex: 0 0 60%;
}
.erbia-mode-desktop .group-top .group-item:last-child {
  flex: 0 0 40%;
}
.erbia-mode-desktop .group-bottom .group-item:first-child {
  flex: 0 0 45%;
}
.erbia-mode-desktop .group-bottom .group-item:last-child {
  flex: 0 0 55%;
}
.erbia-mode-desktop .group-bottom .group-item img {
  z-index: 9;
  top: -25%;
  right: -30px;
}
.erbia-colors {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}
.erbia-colors-items {
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
}
.erbia-colors-items .item {
  flex: 0 0 calc(50% - 40px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  text-align: center;
  position: relative;
  padding-bottom: 50px;
}
.erbia-colors-items .item-color {
  width: 100%;
  padding-top: 100%;
  background-color: white;
  border-radius: 50%;
  border: 2px solid #fff;
}
.erbia-colors-items .item-color.gold-primary {
  background-color: #c19a5b;
}
.erbia-colors-items .item-color.gold-gradient {
  background: linear-gradient(180deg, #e3a455 7.29%, #f6dba6 38.02%, #ffebc3 58.33%, #ba7f3b 100%);
}
.erbia-colors-items .item-color.elevation {
  background: #35353b;
}
.erbia-colors-items .item-color.background {
  background: #0b0c16;
}
.erbia-colors-items .item p {
  font-size: 1.125rem;
  font-weight: 300;
  letter-spacing: 0.1875rem;
  position: absolute;
  bottom: 0;
  color: rgba(255, 255, 255, 0.5);
}
.erbia-fonts {
  overflow: hidden;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
}
.erbia-fonts-content {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.erbia-fonts-content div {
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.erbia-fonts-content div h2 {
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-color: initial;
  background-image: linear-gradient(180deg, #e3a455 7.29%, #f6dba6 38.02%, #ffebc3 58.33%, #ba7f3b 100%);
  background-size: 100%;
  font-size: 2.1875rem;
  letter-spacing: 0.15625rem;
  text-transform: uppercase;
  user-select: none;
}
.erbia-fonts-content div p {
  text-align: left;
  font-size: 1rem;
  line-height: 1.75rem;
  color: rgba(255, 255, 255, 0.5);
}
.erbia-fonts-content div p:nth-child(2) {
  text-transform: uppercase !important;
}
.erbia-fonts-content div.abhaya h2,
.erbia-fonts-content div.abhaya p {
  font-family: "Abhaya Libre", serif !important;
}
.erbia-fonts-content div.inter h2,
.erbia-fonts-content div.inter p {
  font-family: "Inter", sans-serif !important;
}
.erbia-clients {
  margin-top: 100px;
}
.erbia-clients-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.erbia-clients-content img {
  margin-top: 60px;
}
.erbia-clients-content .desktop {
  display: none;
}
@media screen and (min-width: 480px) {
}
@media screen and (min-width: 576px) {
}
@media screen and (min-width: 768px) {
  .erbia-main {
    background-image: url("../../../public/assets/images/erbia/artboard-bck.jpg");
    padding-bottom: 200px;
  }
  .erbia-intro-content {
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: clamp(100px, 15vh, 150px);
  }
  .erbia-intro-content h1 {
    font-size: 4.0625rem;
    line-height: 5rem;
  }
  .erbia-intro-content .logo {
    width: 23%;
    margin-bottom: 70px;
  }
  .erbia-resources-items .item {
    width: 55%;
  }
  .erbia-resources-items .item p {
    margin-top: 30px;
  }
  .erbia-custom-items {
    padding: 0 50px;
  }
  .erbia-mode-mobile {
    /*& .colors {
        &-items {
          padding: 0 70px;
          gap: 50px;

          & .item {
            flex: 0 0 calc(50% - 50px);
          }
        }
      } */
  }
  .erbia-colors-items {
    padding: 0 70px;
    gap: 50px;
  }
  .erbia-colors-items .item {
    flex: 0 0 calc(50% - 50px);
  }
  .erbia-fonts-content div h2,
  .erbia-fonts-content div p {
    text-align: center;
  }
}
@media screen and (min-width: 992px) {
  .erbia-heading span:before,
  .erbia-heading span:after {
    width: 170px;
  }
  .erbia-heading > span:before {
    margin-right: 40px;
  }
  .erbia-heading > span:after {
    margin-left: 40px;
  }
  .erbia-intro {
    background-size: cover;
  }
  .erbia-intro-content h1 {
    font-size: 1.875rem;
    word-spacing: 0;
  }
  .erbia-intro-content .logo {
    width: 25%;
    margin-bottom: 30px;
  }
  .erbia-resources {
    margin-top: 0;
  }
  .erbia-resources-items {
    flex-direction: row;
    align-items: flex-start;
  }
  .erbia-resources-items .item {
    flex: 0 0 33.33%;
    border-right: 1px solid #35353b;
    padding: 10px 0 80px 0;
  }
  .erbia-resources-items .item-icon {
    width: 45%;
    padding-top: 45%;
  }
  .erbia-resources-items .item:last-child {
    border: 0;
  }
  .erbia-resources-items .item p {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .erbia-custom {
    margin-top: 120px;
  }
  .erbia-custom-bck {
    display: block;
    top: -30%;
  }
  .erbia-custom-items {
    gap: 30px;
  }
  .erbia-custom-items .item {
    flex: 0 0 calc(20% - 30px);
  }
  .erbia-mode .login-form {
    flex-direction: row;
    gap: 20px;
    justify-content: center;
  }
  .erbia-mode .login-form img {
    max-width: 45%;
  }
  .erbia-mode-mobile {
    /*& .colors {
        &-items {
          & .item {
            flex-basis: calc(25% - 50px);
          }
        }
      } */
  }
  .erbia-colors-items .item {
    flex-basis: calc(25% - 50px);
  }
}
@media screen and (min-width: 1200px) {
  .erbia-line {
    display: block;
  }
  .erbia-intro {
    height: 100vh;
    background-size: contain;
    background-position: top center;
  }
  .erbia-intro-content {
    padding-bottom: 70px;
  }
  .erbia-intro-content .logo {
    width: clamp(200px, 20%, 350px);
  }
  .erbia-custom {
    margin-top: 150px;
  }
  .erbia-custom-bck {
    top: -100%;
  }
  .erbia-custom-items {
    gap: 50px;
  }
  .erbia-custom-items .item {
    flex: 0 0 calc(20% - 50px);
  }
  .erbia-mode {
    margin-top: 150px;
  }
  .erbia-mode-mobile {
    display: none;
  }
  .erbia-mode-desktop {
    display: block;
  }
  .erbia-mode-desktop .erbia-heading span:before,
  .erbia-mode-desktop .erbia-heading span:after {
    width: 100%;
  }
  .erbia-colors {
    margin-top: 0;
  }
  .erbia-colors-items {
    max-width: 70%;
    row-gap: 50px;
    column-gap: 50px;
    padding: 0;
  }
  .erbia-colors-items .item {
    flex-basis: calc(50% - 50px);
  }
  .erbia-colors-items .item-color {
    width: 75%;
    padding-top: 75%;
  }
  .erbia-colors-items .item p {
    white-space: nowrap;
    font-size: 1rem;
  }
  .erbia-fonts {
    margin-top: 40px;
  }
  .erbia-fonts .erbia-heading span:before,
  .erbia-fonts .erbia-heading span:after {
    min-width: 300px;
  }
  .erbia-fonts-content {
    margin-left: 5%;
    margin-top: 30px;
  }
  .erbia-fonts-content div {
    gap: 15px;
  }
  .erbia-fonts-content div h2,
  .erbia-fonts-content div p {
    text-align: left;
  }
  .erbia-fonts-content div h2 {
    font-size: 2.0625rem;
  }
  .erbia-fonts-content div p {
    font-size: 0.875rem;
  }
  .erbia-clients {
    margin-top: 70px;
  }
  .erbia-clients img {
    margin-top: 90px;
  }
}
@media screen and (min-width: 1600px) {
  .erbia-section-content {
    max-width: 1400px;
  }
  .erbia-intro-content {
    padding-bottom: 100px;
  }
  .erbia-intro-content h1 {
    font-size: 2.1875rem;
  }
  .erbia-mode .erbia-section-content {
    max-width: 1500px;
  }
}
@media screen and (min-width: 2000px) {
  .erbia-heading > span:before,
  .erbia-heading > span:after {
    width: 200px;
  }
  .erbia-intro-content {
    padding-bottom: 180px;
  }
  .erbia-intro-content .logo {
    margin-bottom: 50px;
  }
  .erbia-intro-content h1 {
    font-size: 2.8125rem;
    margin-bottom: 70px;
  }
}
